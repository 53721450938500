import axios from 'axios'
const request = axios.create({
  // baseURL: 'http://192.168.29.208:8081/', // API server url
  //  baseURL: 'http://localhost:8080/', // API server url
      baseURL:'https://sivahotelapi.ideauxbill.in/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
})

export default request
